<template>
  <button class="nd-btn" style="background: red">
    <span v-if="$slots.default"><slot></slot></span>
  </button>
</template>
 
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: "ava-button"
})
</script>
<script setup lang="ts">
</script>
 
<style scoped>
 
</style>