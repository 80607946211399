<template>
  <div class="title-box">
    <div class="title-en">{{ props?.title?.en || undefined }}</div>
    <div class="title-cn">{{ props?.title?.cn || undefined }}</div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "ava-title",
});
</script>
<script lang="ts" setup>
import {defineProps} from 'vue'
const props = defineProps(
  {
    title: Object as {en: string, cn: string}
  })
</script>

<style scoped>
.title-box {
  text-align: center;
}
.title-en {
  font-size: 50px;
  font-family: TypeLand KhangXi Dict Trial;
  font-weight: 400;
  color: #999999;
  background: linear-gradient(180deg, #999999 0%, #ffffff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.title-cn {
  font-size: 36px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #000000;
  margin-top: -45px;
}
</style>
